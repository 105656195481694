import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Col, Row } from 'react-bootstrap'
import MD5 from 'crypto-js/md5'
import jsPDF from 'jspdf'
import '../CreateReport/OpenSans-Regular-normal'
import WallpaperCard from '../../assets/images/wallpaper-card.png'
import PadiLogoTransparent from '../../assets/images/padi-logo-transparent.png'
import { QRCode } from 'react-qrcode-logo'
import { Button } from '@material-ui/core'
import logo from '../../assets/images/logo-2.jpg'

export default class ModalCardShareButton extends Component {
  state = {
    doc: null
  }

  constructor(props) {
    super(props)
    const IDs = this.props.StudyInstanceUID.split('.')
    const slicedPwd = MD5(IDs.pop()).toString()
    this.PWD = slicedPwd.slice(slicedPwd.length - 8)
    this.OHIFLink = `${window.location.protocol}//${window.location.host}/external/${this.props.orthanc ? 'osimis' : 'ohif'}/` //changes made by rishabh (25-05-2022)
  }


  generateCard = async () => {
    // console.log(this.props)
    const doc = new jsPDF({
      orientation: 'landscape',
      format: 'credit-card',
      compress: true
    })
    await this.addImageToDoc(doc, WallpaperCard)
    await this.addImageToDoc(doc, PadiLogoTransparent, 43, 6, 38, 24)
    const el = document.getElementById('testqr')
    const canvas = el.children[0]
    const dataURL = canvas.toDataURL("image/jpeg,1.0")
    doc.addImage(dataURL, 'JPEG', 6, 6, 30, 30)

    this.addWrappedText({
      text: 'Access Code: ' + this.PWD, // Put a really long string here
      textWidth: 210,
      doc,

      // Optional
      fontSize: '10',
      fontType: 'OpenSans-Regular',
      lineSpacing: 7,               // Space between lines
      xPosition: 2,                // Text offset from left of document
      initialYPosition: 43,         // Initial offset from top of document; set based on prior objects in document
      pageWrapInitialYPosition: 10  // Initial offset from top of document when page-wrapping
    });
    this.addWrappedText({
      url: this.OHIFLink + this.props.StudyInstanceUID,
      text: 'Click Here: ' + this.OHIFLink + ' ' + this.props.StudyInstanceUID, // Put a really long string here
      textWidth: 100,
      doc,

      // Optional
      fontSize: '8',
      fontType: 'OpenSans-Regular',
      lineSpacing: 7,               // Space between lines
      xPosition: 2,                // Text offset from left of document
      initialYPosition: 48,         // Initial offset from top of document; set based on prior objects in document
      pageWrapInitialYPosition: 10  // Initial offset from top of document when page-wrapping
    });
    this.setState({ doc })
  }

  addImageToDoc = async (doc, src, posx = 0, posy = 0, width = 100, height = 100) => {
    const img = new Image()
    img.crossOrigin = "";
    img.src = src;
    await img.decode()
    doc.addImage(img, 'png', posx, posy, width, height, '', 'FAST')
  }

  addWrappedText = ({ url, text, textWidth, doc, fontSize = 14, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10 }) => {
    const textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
    const pageHeight = doc.internal.pageSize.height;        // Get page height, well use this for auto-paging
    doc.setFont(fontType);
    doc.setFontSize(fontSize);

    let cursorY = initialYPosition;

    doc.text(xPosition, cursorY, textLines)
    if (url) {
      doc.link(0, cursorY - 4, textWidth, 20, { url })
    }
  }

  downloadDoc = async () => {
    await this.state.doc.save()
  }

  render = () => {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} onClick={(e) => e.stopPropagation()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark">
          <Row style={{ textAlign: 'center' }}>
            <Col
              id="testqr"
              style={{ display: 'none' }}
            >
              <QRCode
                size={300}
                logoWidth={200}
                logoHeight={50}
                logoOpacity={0.6}
                value={this.OHIFLink + this.props.StudyInstanceUID}
                logoImage={logo}
              />
            </Col>
            {this.state.doc !== null && (
              <div>
                <h3>Preview</h3>
                <iframe style={{ border: 'none', height: 260, width: '100%' }} src={this.state.doc.output('bloburl')}></iframe>
              </div>
            )}
            <br />
            <Button onClick={this.generateCard}>Generate PDF card</Button>
            <br />
            <Button disabled={this.state.doc === null} onClick={this.downloadDoc}>Download PDF card</Button>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}
