import ModalCardShareButtonDownloadLight from "./ModalCardShareButtonDownloadLight";
import React from "react";
import activity from "../../services/activity";

export class CardShareButtonDownloadLight extends React.Component {
  state = {
    show: false,
  };

  openModify = () => {
    this.setState({ show: true });
    activity.create_activity(
      "CREATE SHARE CARD",
      "Share Card with Download (Light)"
    );
  };

  render() {
    //  console.log(this.props);
    return this.props.StudyInstanceUID ? (
      <>
        <button
          className="dropdown-item bg-green"
          type="button"
          onClick={this.openModify}
        >
          Share Card with Download (Light)
        </button>
        <ModalCardShareButtonDownloadLight
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          modify={() => this.modify()}
          StudyInstanceUID={this.props.StudyInstanceUID}
        />
      </>
    ) : null;
  }
}
