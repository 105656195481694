const CHEST_X_RAY = `CHEST RADIOGRAPH PA ERECT

Comparison made with CXR done 8.12.2020

Findings:
Right moderate pleural effusion.
Mild left pleural effusion, pleural drain in situ
Ground glass opacities in both mid zones and left lower zone.
Parts of the right mediastinal and heart outlines are obscured.
No bone abnormalities.

Impression:

Right moderate pleural effusion.
Mild left pleural effusion, pleural drain in situ
Ground glass opacities in both mid zones and left lower zone.

Reported by:
Dr Anas Bin Tharek
Radiologist
MD(MSMU), MMedRad (UPM)
MMC (63070)
`

export default CHEST_X_RAY;