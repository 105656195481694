const ABDOME_PELVIS_FEMALE = `US ABDOMEN AND PELVIS

Findings:
The liver is homogenous and smooth outline. The liver is normal in size. No focal liver lesion.
No intra or extrahepatic biliary duct dilatation.
Portal vein is patent and normal in caliber.
Gall bladder is well distended. No GB wall thickening, pericholecystic fluid or calculus.
Visualised pancreas and spleen are normal.
Both kidneys are normal in size. No hydronephrosis or calculus.
Urinary bladder is normal and well distended.
No ascites.
Uterus is normal in appearance. 
Both ovaries are not visualized. No pelvic adnexal masses. 

Impression:
Normal ultrasound abdomen and pelvis.

Reported by:
Dr Anas Bin Tharek
Radiologist
MD(MSMU), MMedRad (UPM)
MMC (63070)
`
export default ABDOME_PELVIS_FEMALE;