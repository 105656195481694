import React, { useEffect, useState } from "react";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import UserActivity from "./UserActivity";
import SystemMonitoring from "./SystemMonitoring.js";
import MyDashboard from "./MyDashboard";
import { useSelector } from "react-redux";
/**
 * Root Panel of Monitor route
 * Using React Hooks
 */

const MonitoringRoot = () => {
  const [selectedOptionMenu, setSelectedOptionMenu] = useState("");
  const roles = useSelector((state) => state.PadiMedical.roles);

  useEffect(() => {
    if (roles.view_system_monitoring) {
      return setSelectedOptionMenu("SystemMonitoring");
    } else if (roles.view_user_activity) {
      return setSelectedOptionMenu("UserActivity");
    } else if (roles.view_my_dashboard) {
      return setSelectedOptionMenu("MyDashboard");
    }
  }, []);
  function clickHandler(event) {
    setSelectedOptionMenu(event.target.value);
  }

  function getComponentToDisplay() {
    switch (selectedOptionMenu) {
      case "SystemMonitoring":
        return <SystemMonitoring />;
      case "UserActivity":
        return <UserActivity />;
      case "MyDashboard":
        return <MyDashboard />;
      default:
        return [];
    }
  }

  return (
    <Row>
      <Col sm={3} className="border-end border-2">
        <Navbar
          className="d-flex flex-row d-flex justify-content-start align-items-center"
          collapseOnSelect
          expand="lg"
          variant="dark"
        >
          <Navbar.Toggle />
          <nav className="d-flex flex-column text-justify justify-content-start align-items-center">
            {roles.view_system_monitoring && (
              <Nav className="me-auto mb-3 d-flex align-items-center">
                <button
                  id="icoGeneral"
                  type="button"
                  value="SystemMonitoring"
                  className={
                    "sub-btn-admin" +
                    (selectedOptionMenu === "SystemMonitoring"
                      ? " sub-btn-admin-active"
                      : "")
                  }
                  onClick={clickHandler}
                >
                  <i className="fas fa-arrow-circle-right pe-2"></i>System
                  Monitoring
                </button>
              </Nav>
            )}
            {roles.view_user_activity && (
              <Nav className="me-auto mb-3 d-flex align-items-center">
                <button
                  id="icoUser"
                  type="button"
                  value="UserActivity"
                  className={
                    "sub-btn-admin" +
                    (selectedOptionMenu === "UserActivity"
                      ? " sub-btn-admin-active"
                      : "")
                  }
                  onClick={clickHandler}
                >
                  <i className="fas fa-arrow-circle-right pe-2"></i>Users
                  Activity
                </button>
              </Nav>
            )}

            {roles.view_my_dashboard && (
              <Nav className="me-auto mb-3 d-flex align-items-center">
                <button
                  id="icoUser"
                  type="button"
                  value="MyDashboard"
                  className={
                    "sub-btn-admin" +
                    (selectedOptionMenu === "MyDashboard"
                      ? " sub-btn-admin-active"
                      : "")
                  }
                  onClick={clickHandler}
                >
                  <i className="fas fa-arrow-circle-right pe-2"></i>My Dashboard
                </button>
              </Nav>
            )}
          </nav>
        </Navbar>
      </Col>
      <Col sm={9} className="ps-5">
        {getComponentToDisplay()}
      </Col>
    </Row>
  );
};

export default MonitoringRoot;
